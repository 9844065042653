.infinity {
  font-size: 25px;
}

.historyText {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0 10px;
}

.historyPic {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: start;
}

.historyImage {
  width: 70%;
}

@media only screen and (height <= 600px) {
  .historyImage {
    width: 60%;
  }
}