@import url(./vendor/fonts/fonts.css);
:root {
  /* --main-font-color: #77b2a3; */
  --main-font-color: #17b089;
}

* {
  padding: 0;
  margin: 0;
}
body {
  font-family: "Source Serif Pro", serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  color: var(--main-font-color);
  overflow: hidden;
}

.page {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  min-height: calc(100vh);
}

.page::before {
  z-index: -1;
  content: "";
  position: absolute;
  /* top: 0; */
  left: 0;
  transform: rotate(180deg);
  width: 100px;
  height: 100px;
  /* background-color: aqua; */
  background-image: url("./vendor/images/mainBgFlowers.png");
  background-size: 100px 100px;
  background-repeat: no-repeat;
  /* display: block; */
}
.page::after {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-image: url("./vendor/images/mainBgFlowers.png");
  background-size: 100px 100px;
  background-repeat: no-repeat;
}

.container {
  min-width: 320px;
  margin: 15% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 73vh; */
  min-height: 350px;
}

.section {
  display: flex;
  flex-direction: column;
}

@media only screen and (width <= 320px) {
  .container {
    width: 280px;
  }
  .page::after {
    display: none;
  }
}

@media only screen and (height <= 600px) {
  body {
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .page::after {
    display: none;
  }
  body::-webkit-scrollbar {
    display: none;
  }

  .container {
    margin: 5% 0;
  }
}
