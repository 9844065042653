.contactsTitle {
    text-align: center;
    font-size: 18px;
}

.contactsImg {
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

.historyText {
    text-align: center;
}

.contactsImg2 {
    width: 40%;
    padding: 5%;
}

.contacts {
    padding-top: 15%;
}