.programTextBold {
    font-weight: bold;
    padding-bottom: 0;
}

.programText {
    text-align: center;
    padding: 53px 60px;
}

.programTitle {
    margin: 10px;
    font-size: medium;
    padding-top: 55px;
}

.program {
    position: relative;
}

.program::before {
    z-index: 999;
    content: "";
    position: absolute;
    top: 0;
    left: 0; 
    transform: rotate(0deg);
    width: 60%;
    height: 110%;
    background-image: url("../../vendor/images/programBgLeft.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.program::after {
    z-index: 999;
    content: "";
    position: absolute;
    top: 0;
    right: 0; 
    transform: rotate(180deg);
    width: 60%;
    height: 110%;
    background-image: url("../../vendor/images/programBgLeft.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

@media only screen and (height <= 600px) {
    .programTitle {
        padding-top: 5px;
    }
    .program::before {
        z-index: 999;
        content: "";
        position: absolute;
        top: 0;
        left: 0; 
        transform: rotate(0deg);
        width: 50%;
        height: 102%;
        background-image: url("../../vendor/images/programBgLeft.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    
    .program::after {
        z-index: 999;
        content: "";
        position: absolute;
        top: 0;
        right: 0; 
        transform: rotate(180deg);
        width: 50%;
        height: 102%;
        background-image: url("../../vendor/images/programBgLeft.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
  }