.invite {
    padding: 0 20px;
    text-align: center;
}

.invite::before {
    z-index: 999;
    content: "";
    position: absolute;
    /* top: 0; */
    left: 0; 
    transform: rotate(0deg);
    width: 70px;
    height: 70px;
    background-image: url("../../vendor/images/quotes.png");
    background-size: 70px 70px;
    background-repeat: no-repeat;
}

.invite::after {
    z-index: 999;
    content: "";
    position: absolute;
    bottom: 100px;
    right: 0; 
    transform: rotate(180deg);
    width: 70px;
    height: 70px;
    background-image: url("../../vendor/images/quotes.png");
    background-size: 70px 70px;
    background-repeat: no-repeat;
}

.couple {
    height: 100%;
    width: 80px;
}

@media only screen and (height <= 600px) {
    .invite::before {
       display: none;
    }
    
    .invite::after {
        display: none;
    }
  }