.menu {
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    font-weight: bold;
    /* max-width: 200px; */
    margin-left: 30%;
    height: 83px;
    padding: 10px 0px 0 0;
}

.menuLink {
    color: inherit;
    text-decoration: none;
}

.active {
    border-bottom: 2px solid var(--main-font-color);
}

@media only screen and (width <= 360px) {
    .menu {
        gap: 7px;
        margin-left: 30%;
        font-size: 15px;
    }
}

@media only screen and (width <= 320px) {
    .menu {
        gap: 7px;
        margin-left: 40%;
        font-size: 14px;
        width: 70%;
    }
  }