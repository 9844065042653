.dresscodeImg {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.dresscode {
    text-align: center;
    max-width: 800px;
}

.dresscodeImg2 {
    width: 100%;
    max-width: 400px;
    transform: rotate(180deg);
    margin: 0 auto;
}