.form {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  
  .wedForm {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  
  }
  .pollTitle {
    font-size: 26px;
  }
  .wedForm label {
    /* font-style: italic; */
    font-size: 14px;
    font-weight: 700;
  }
  .wedFormInput {
    background-color:#fff;
    color: #709F82;
    border: 1px dashed #9ab880;
    border-radius: 25px;
    min-height: 30px;
    margin: 5px;
    padding: 5px;
    font-size: 18px;
  }
  
  .WedFormButton {
    font-family: "Playfair Display", serif;
    background-color: #709F82;
    color: white;
    border: none;
    min-height: 35px;
    border-radius: 25px;
    padding: 15px;
    margin: 5px;
    text-transform: uppercase;
    font-size: large;
    cursor: pointer;
  }

  .envelope {
    width: 40%;
  }