.home {
    /* position: relative; */
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.homeTitle {
    font-family: 'Alice', serif;
    font-size: 48px;
    text-align: center;
    letter-spacing: 10px;
    line-height: 130%;
    width: 100%;
    padding: 0 60px;
}
.homeTitle span {
    display: block;
}

.homeTitle span:first-child {
    text-align: left;
}
.homeTitle span:last-child {
    text-align: right;
}

.watch {
    height: 35%;
    width: 35%;
    margin-left: 25%;
}

.timer {
    position: absolute;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items:flex-start;
}

.counter {
    display: flex;
    /* width: 100%; */
    border: 1px solid var(--main-font-color);
    padding: 10px 10px 10px 10px;
    gap: 5px;
    font-size: 14px;
    justify-content: center;
}
/* .home::before {
    content: "";
    transform: rotate(180deg);
    width: 150px;
    height: 150px;
    background-color: aqua;
    background-image: url("../../vendor/images/mainBgFlowers.png");
    background-size: 150px 150px;
    background-repeat: no-repeat;
    display: block;
} */